import { AccountApi } from '@blank/api'
import { useActivateTokamakCardModal } from 'modules/bank/modals/card/ActivateTokamakCardModal/useActivateTokamakCardModal'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'

interface Props {
  account: AccountApi
}

export const ActivateTokamakCardButton = ({ account }: Props) => {
  const { t } = useTranslation('home-components')

  const { showModal: showActivateTokamakCardModal } =
    useActivateTokamakCardModal({
      limitActivationDate: account.limitActivationDate || '',
    })

  return (
    <Button
      onClick={showActivateTokamakCardModal}
      variant="primary"
      className="mt-auto w-fit"
      color="default"
    >
      {t('bankCardActivationCard.ctaLabel')}
    </Button>
  )
}
