import { EnumBillingServiceId } from '@blank/api'
import { IllustrationName } from 'modules/common/types/illustrations'

export enum EnumServiceSlugs {
  FACTORING = 'affacturage',
  CREDIT = 'credit',
  PAYMENT_TERMINAL = 'terminal-paiement',
  ACCOUNTING = 'expertise-comptable',
  SIMPLIS_INSURANCE = 'mutuelle-simplis',
  SIMPLIS_RC_PRO = 'rc-pro-simplis',
  SIMPLIS_DECADE_GUARANTEE = 'garantie-decennale-simplis',
  SIMPLIS_PREVOYANCE = 'prevoyance-simplis',
  SIMPLIS_MULTIRISK = 'assurance-multirisque-simplis',
  TDAE_URSSAF = 'tierce-declaration-urssaf',
  OTHER_SERVICES = 'autres-services',
  FINANCING = 'financements-importants',
  WEBSITE_CREATION = 'creation-web-site',
  DIGITAL_VISIBILITY = 'visibilite-digitale',
  RETIREMENT_PLAN = 'complementaire-retraite',
  ELECTRONIC_INVOICE = 'facturation-electronique',
}

export type FeaturedService =
  | EnumBillingServiceId.FACTORING
  | EnumBillingServiceId.CREDIT
  | EnumBillingServiceId.PAYMENT_TERMINAL
  | EnumBillingServiceId.ACCOUNTING
  | EnumBillingServiceId.URSSAF
  | EnumBillingServiceId.HEALTHCARE
  | EnumBillingServiceId.PROFESSIONAL_LIABILITY
  | EnumBillingServiceId.PROVIDENT_INSURANCE
  | EnumBillingServiceId.MULTIRISK_INSURANCE
  | EnumBillingServiceId.TEN_YEAR_GUARANTEE
  | EnumBillingServiceId.OTHER_SERVICES
  | EnumBillingServiceId.FINANCING
  | EnumBillingServiceId.WEBSITE_CREATION
  | EnumBillingServiceId.DIGITAL_VISIBILITY
  | EnumBillingServiceId.RETIREMENT_PLAN
  | EnumBillingServiceId.ELECTRONIC_INVOICE

export const MAP_SERVICE_ID_TO_ILLUSTRATION: Partial<
  Record<FeaturedService, IllustrationName>
> = {
  [EnumBillingServiceId.FACTORING]: 'factoring',
  [EnumBillingServiceId.CREDIT]: 'double-coin-logo',
  [EnumBillingServiceId.PAYMENT_TERMINAL]: 'tpe',
  [EnumBillingServiceId.ACCOUNTING]: 'calendar-calculator',
  [EnumBillingServiceId.URSSAF]: 'invoicing-tool',
  [EnumBillingServiceId.HEALTHCARE]: 'health-insured',
  [EnumBillingServiceId.PROFESSIONAL_LIABILITY]: 'civil-liability',
  [EnumBillingServiceId.PROVIDENT_INSURANCE]: 'foresight-shield',
  [EnumBillingServiceId.MULTIRISK_INSURANCE]: 'society-shield',
  [EnumBillingServiceId.TEN_YEAR_GUARANTEE]: 'tool-shield',
  [EnumBillingServiceId.OTHER_SERVICES]: 'services',
  [EnumBillingServiceId.FINANCING]: 'financement',
  [EnumBillingServiceId.DIGITAL_VISIBILITY]: 'pin',
  [EnumBillingServiceId.WEBSITE_CREATION]: 'imac-phone',
  [EnumBillingServiceId.RETIREMENT_PLAN]: 'pension',
  [EnumBillingServiceId.ELECTRONIC_INVOICE]: 'document',
}

export const MAP_SERVICE_ID_TO_SLUG: Record<FeaturedService, EnumServiceSlugs> =
  {
    [EnumBillingServiceId.FACTORING]: EnumServiceSlugs.FACTORING,
    [EnumBillingServiceId.CREDIT]: EnumServiceSlugs.CREDIT,
    [EnumBillingServiceId.PAYMENT_TERMINAL]: EnumServiceSlugs.PAYMENT_TERMINAL,
    [EnumBillingServiceId.ACCOUNTING]: EnumServiceSlugs.ACCOUNTING,
    [EnumBillingServiceId.URSSAF]: EnumServiceSlugs.TDAE_URSSAF,
    [EnumBillingServiceId.HEALTHCARE]: EnumServiceSlugs.SIMPLIS_INSURANCE,
    [EnumBillingServiceId.PROFESSIONAL_LIABILITY]:
      EnumServiceSlugs.SIMPLIS_RC_PRO,
    [EnumBillingServiceId.PROVIDENT_INSURANCE]:
      EnumServiceSlugs.SIMPLIS_PREVOYANCE,
    [EnumBillingServiceId.MULTIRISK_INSURANCE]:
      EnumServiceSlugs.SIMPLIS_MULTIRISK,
    [EnumBillingServiceId.TEN_YEAR_GUARANTEE]:
      EnumServiceSlugs.SIMPLIS_DECADE_GUARANTEE,
    [EnumBillingServiceId.OTHER_SERVICES]: EnumServiceSlugs.OTHER_SERVICES,
    [EnumBillingServiceId.FINANCING]: EnumServiceSlugs.FINANCING,
    [EnumBillingServiceId.WEBSITE_CREATION]: EnumServiceSlugs.WEBSITE_CREATION,
    [EnumBillingServiceId.DIGITAL_VISIBILITY]:
      EnumServiceSlugs.DIGITAL_VISIBILITY,
    [EnumBillingServiceId.RETIREMENT_PLAN]: EnumServiceSlugs.RETIREMENT_PLAN,
    [EnumBillingServiceId.ELECTRONIC_INVOICE]:
      EnumServiceSlugs.ELECTRONIC_INVOICE,
  }

export const MAP_SERVICE_SLUG_TO_ID: Record<EnumServiceSlugs, FeaturedService> =
  {
    [EnumServiceSlugs.FACTORING]: EnumBillingServiceId.FACTORING,
    [EnumServiceSlugs.CREDIT]: EnumBillingServiceId.CREDIT,
    [EnumServiceSlugs.PAYMENT_TERMINAL]: EnumBillingServiceId.PAYMENT_TERMINAL,
    [EnumServiceSlugs.ACCOUNTING]: EnumBillingServiceId.ACCOUNTING,
    [EnumServiceSlugs.TDAE_URSSAF]: EnumBillingServiceId.URSSAF,
    [EnumServiceSlugs.SIMPLIS_INSURANCE]: EnumBillingServiceId.HEALTHCARE,
    [EnumServiceSlugs.SIMPLIS_RC_PRO]:
      EnumBillingServiceId.PROFESSIONAL_LIABILITY,
    [EnumServiceSlugs.SIMPLIS_PREVOYANCE]:
      EnumBillingServiceId.PROVIDENT_INSURANCE,
    [EnumServiceSlugs.SIMPLIS_MULTIRISK]:
      EnumBillingServiceId.MULTIRISK_INSURANCE,
    [EnumServiceSlugs.SIMPLIS_DECADE_GUARANTEE]:
      EnumBillingServiceId.TEN_YEAR_GUARANTEE,
    [EnumServiceSlugs.OTHER_SERVICES]: EnumBillingServiceId.OTHER_SERVICES,
    [EnumServiceSlugs.FINANCING]: EnumBillingServiceId.FINANCING,
    [EnumServiceSlugs.WEBSITE_CREATION]: EnumBillingServiceId.WEBSITE_CREATION,
    [EnumServiceSlugs.DIGITAL_VISIBILITY]:
      EnumBillingServiceId.DIGITAL_VISIBILITY,
    [EnumServiceSlugs.RETIREMENT_PLAN]: EnumBillingServiceId.RETIREMENT_PLAN,
    [EnumServiceSlugs.ELECTRONIC_INVOICE]:
      EnumBillingServiceId.ELECTRONIC_INVOICE,
  }
